// Status Indicator

import { Box, CircularProgress } from "custom_exports/material_ui_items";

function ShowStatus(props) {
	//--------------------------------- variables ---------------------------------//
	const { statusType, message, extraSX } = props;

	//--------------------------------- functions ---------------------------------//
	const createLoadingIndicator = () => {
		return (
			<Box
				sx={{
					display: "flex",
					position: "fixed",
					width: "100%",
					top: 0,
					bottom: 0,
					justifyContent: "center",
					alignItems: "center",
					...extraSX,
				}}
			>
				<CircularProgress />
			</Box>
		);
	};

	const createErrorIndicator = () => {
		let component = null;

		return component;
	};

	const createEmptyDataIndicator = () => {
		let component = null;

		return component;
	};

	const createComponent = () => {
		let component = null;

		if (statusType === "loading" || statusType === "waiting") {
			component = createLoadingIndicator();
		} else if (statusType === "error") {
			component = createErrorIndicator();
		} else if (statusType === "empty_data") {
			component = createEmptyDataIndicator();
		}

		return component;
	};

	//--------------------------------- contents ----------------------------------//
	const content = createComponent();

	return content;
}

export default ShowStatus;
