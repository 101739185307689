import apiSlice from "services/redux/reducers/apiSlice";
import { BACK_END_API_END_POINTS as AEP } from "app/settings/settings";

const usersApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		RUser: builder.mutation({
			query: (inputParams = {}) => ({
				url: AEP.users.RUser.url,
				method: AEP.users.RUser.method,
				body: { ...inputParams },
			}),
		}),
	}),
});

export const { useRUserMutation } = usersApi;

export default usersApi;
