// App Theme - MUI

import { createTheme } from "custom_exports/material_ui_items";
import { THEME } from "app/settings/settings";

function getTheme({ theme }) {
	theme ||= "default";
	if (theme === "default") {
		theme = createTheme({
			palette: THEME.app.colors,
			breakpoints: THEME.app.screen_breakpoints,
		});
	}

	return theme;
}

export { getTheme };
