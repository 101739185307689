// Default Page Cover

import { Outlet } from "react-router-dom";
import {
	Box,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Stack,
	Button,
} from "custom_exports/material_ui_items";

function DefaultPageCover(props) {
	//--------------------------------- variables ---------------------------------//
	const { pageDetails } = props;
	const { title: pageTitle, icon: PageTitleIcon } = pageDetails ?? {};

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
			<AppBar
				position="sticky"
				color="inherit"
				elevation={0}
				sx={{ borderRadius: 3, zIndex: 900 }}
			>
				<Toolbar sx={{ justifyContent: "space-between" }}>
					<Stack direction="row" alignItems="center">
						{/* {PageTitleIcon && <PageTitleIcon />} */}
						<Typography
							sx={{
								fontWeight: 700,
								fontSize: 24,
								color: "grey.dark",
							}}
						>
							{pageTitle}
						</Typography>
					</Stack>
					{/* <Stack>
						<Button variant="contained">Add New</Button>
					</Stack> */}
				</Toolbar>
			</AppBar>

			<Box sx={{ width: "100%", pt: 0.2 }}>
				<Outlet />
			</Box>
		</Box>
	);

	return content;
}

export default DefaultPageCover;
