import { Outlet } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "custom_exports/material_ui_items";
// import CopyRight from "components/common/CopyRight";
import { getTheme } from "components/themes/AppThemes";

function AppCover() {
	//--------------------------------- variables ---------------------------------//
	const defaultTheme = getTheme({});

	//--------------------------------- contents ----------------------------------//
	const content = (
		<ThemeProvider theme={defaultTheme}>
			<CssBaseline />
			<Outlet />
			{/* <CopyRight sx={{ mt: 4, mb: 4 }} /> */}
		</ThemeProvider>
	);

	return content;
}

export default AppCover;
