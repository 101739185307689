// --------- Complete Keywords --------- //

import APP_KEYWORDS from "app/appKeywords";

const KEYWORDS = {
	showProductsPageFilterMenu: "showProductsPageFilterMenu",
	hideProductsPageFilterMenu: "hideProductsPageFilterMenu",
	showProductCategoryDrawer: "showProductCategoryDrawer",
	hideProductCategoryDrawer: "hideProductCategoryDrawer",

	selectProductCategory: "selectProductCategory",
	resetProductCategoriesFilter: "resetProductCategoriesFilter",
	addProductToCart: "addProductToCart",
	incrementCartItem: "incrementCartItem",
	decrementCartItem: "decrementCartItem",
	showCartDrawer: "showCartDrawer",
	showCustomerSelectionList: "showCustomerSelectionList",
	hideCustomerSelectionList: "hideCustomerSelectionList",
	placeOrder: "placeOrder",

	markProductAsFavourite: "markProductAsFavourite",
	unMarkProductAsFavourite: "unMarkProductAsFavourite",
	addProductsAsCustomerFavourite: "addProductsAsCustomerFavourite",
	removeProductsAsCustomerFavourite: "removeProductsAsCustomerFavourite",

	showFavouriteProducts: "showFavouriteProducts",
	hideFavouriteProducts: "hideFavouriteProducts",
	toggleFavouriteProducts: "toggleFavouriteProducts",

	...APP_KEYWORDS,
};

export default KEYWORDS;
