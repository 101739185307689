// NavigationGroups

import { List, Box, Typography } from "custom_exports/material_ui_items";
import NavigationItem from "./NavigationItem";

function NavigationGroups(props) {
	//--------------------------------- variables ---------------------------------//
	const { group } = props;

	//--------------------------------- contents ----------------------------------//
	const navigations = group.children?.map((child) => {
		switch (child.type) {
			case "single":
				return !child.disabled ? (
					<NavigationItem key={child.id} item={child} level={1} />
				) : null;

			default:
				return null;
		}
	});

	const content = (
		<List
			subheader={
				group.title &&
				group.children.filter((item) => !item.disabled).length > 0 && (
					<Box sx={{ pl: 1, mb: 1.5 }}>
						<Typography variant="subtitle2" color="grey.light">
							{group.title}
						</Typography>
					</Box>
				)
			}
			sx={{ mb: 1.5, py: 0, zIndex: 0 }}
		>
			{navigations}
		</List>
	);

	return content;
}

export default NavigationGroups;
