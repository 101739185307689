import { forwardRef } from "react";
import { useResizeObserver } from "custom_exports/all_hooks";
import mergeRefs from "common/react_handlers/mergeRefs";

const style = {
	flex: 1,
	width: "100%",
	height: "100%",
	minHeight: 0,
	minWidth: 0,
};

const FillFlexParent = forwardRef((props, forwardRef) => {
	const { ref, width, height } = useResizeObserver();
	return (
		<div style={style} ref={mergeRefs(ref, forwardRef)}>
			{width && height ? props.children({ width, height }) : null}
		</div>
	);
});

export default FillFlexParent;
