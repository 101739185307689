// Authentication Based Routing

import { Navigate, Outlet } from "react-router-dom";
import {
	useRef,
	useSelector,
	useLocation,
	useDispatch,
	useRUserMutation,
	useEnumerate,
} from "custom_exports/all_hooks";
import { authActions, getUser, getAccessToken } from "services/redux/reducers/slice";
import ShowStatus from "components/common/ShowStatus";
import { callApi } from "common/api_handlers/api_call_manager";
import A from "common/actions/actions";
import K from "common/keywords";

function RequireAuthentication(props) {
	const isInitialRenderRef = useRef(true);
	//--------------------------------- variables ---------------------------------//
	const { setCredentials } = authActions;

	const dispatch = useDispatch();
	const currentLocation = useLocation();

	const [RUserApiCaller, RUserApiResult] = useRUserMutation();

	const user = useSelector(getUser);
	const accessToken = useSelector(getAccessToken);

	//--------------------------------- functions ---------------------------------//
	// ---- api managers ---- //
	const onApiResponse = ({ apiCallerId, ...response }) => {
		console.log(response);
		const { data } = response;
		if (apiCallerId === K.RUser) {
			const user = data.users.item;
			dispatch(setCredentials({ accessToken, user }));
			// window.location.reload(true);
		}
	};

	const onApiError = ({ apiCallerId, ...error }) => {
		if (apiCallerId === K.RUser) {
			A.logout({ dispatch });
		}
	};

	const apiActions = { onApiResponse, onApiError };

	const callRUserApi = (apiInputParams = {}, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.RUser,
			apiCaller: RUserApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	useEnumerate([{ func: callRUserApi }], "infinite", {
		callerId: K.RUser,
		testCondition: isInitialRenderRef.current && !user,
	});

	// setTimeout(() => {
	// 	!user && A.logout({ dispatch });
	// }, 10000);

	//--------------------------------- contents ----------------------------------//
	let content = null;

	const outlet = <Outlet />;

	const loadingIndication = (
		<ShowStatus statusType="loading" extraSX={{ bgcolor: "white", zIndex: 1000 }} />
	);

	const navigateToLoginPage = (
		<Navigate to="/auth/login" state={{ from: currentLocation }} replace />
	);

	if (accessToken) {
		content = (
			<>
				{!user && loadingIndication}
				{outlet}
			</>
		);
	} else {
		content = navigateToLoginPage;
	}

	// const content = user && accessToken ? outlet : navigateToLoginPage;
	// use three coondition, if u and a -outlet, if !u but a then call user from db show loading
	// if !u and !a login page

	//------------------------------------ end ------------------------------------//
	isInitialRenderRef.current = false;

	return content;
}

export default RequireAuthentication;
