import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "assets/css/index.css";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "services/redux/store";
import reportWebVitals from "app/reportWebVitals";
import App from "app/App";

const documentRootElement = document.getElementById("root");
const root = createRoot(documentRootElement);
root.render(
	<StrictMode>
		<ReduxProvider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</PersistGate>
		</ReduxProvider>
	</StrictMode>
);

reportWebVitals();
