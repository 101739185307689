// Left Side Bar

import { useTheme, useMediaQuery } from "custom_exports/all_hooks";
import { Box, Drawer } from "custom_exports/material_ui_items";
import Scroll from "components/custom/scroll/Scroll";
import LeftSideBarHeader from "components/layouts/app_layout/side_bars/left/contents/LeftSideBarHeader";
import LeftSideBarContents from "components/layouts/app_layout/side_bars/left/contents/LeftSideBarContents";

function LeftSideBar(props) {
	//--------------------------------- variables ---------------------------------//
	const { leftSideBar, handleLeftSideBarToggle, window } = props;
	const theme = useTheme();
	const isViewingOnTablet = useMediaQuery(theme.breakpoints.down("laptop"));
	// responsive drawer container
	const container = window !== undefined ? () => window().document.body : undefined;

	//--------------------------------- contents ----------------------------------//
	const screenOverlaySideBar = (
		<Drawer
			container={container}
			open={leftSideBar.opened}
			variant="temporary"
			onClose={handleLeftSideBarToggle}
			ModalProps={{ keepMounted: true }}
			sx={{
				// display: { xs: "block", lg: "none" },
				display: { xs: "block", lg: "block" },
				"& .MuiDrawer-paper": {
					boxSizing: "border-box",
					width: 260,
					borderRight: `5px solid`,
					borderColor: "primary.lighter",
					backgroundImage: "none",
					boxShadow: "inherit",
					overflow: "hidden",
				},
			}}
		>
			<LeftSideBarHeader />
			<Scroll>
				<LeftSideBarContents />
			</Scroll>
		</Drawer>
	);

	const content = (
		<Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}>
			{screenOverlaySideBar}
		</Box>
	);

	return content;
}

export default LeftSideBar;
