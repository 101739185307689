// Copyright Block

import { Box, Typography } from "custom_exports/material_ui_items";
import { VERSION, APP_PROVIDER, APP_ACCOUNT } from "app/settings/settings";

function CopyRight(props) {
	//--------------------------------- contents ----------------------------------//
	const content = (
		<Box {...props}>
			<Typography variant="body2" color="grey.light" align="center" gutterBottom>
				{"Copyright © "} {new Date().getFullYear()}
				{". "} {APP_ACCOUNT} {". All rights reserved"}
			</Typography>
			<Typography variant="body2" color="grey.light" align="center" sx={{ fontSize: 11 }}>
				Made In Canada - {VERSION}
			</Typography>
		</Box>
	);

	return content;
}

export default CopyRight;
