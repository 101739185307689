import { STATUS_CODES as SC } from "common/constants";

function processLoginResponseError(error) {
	let refinedError = { errorMessage: "Login Failed" };

	if (!error?.status) {
		refinedError.errorMessage = "No Server Response";
	} else if (error.status?.code === SC.FAIL.UN_AUTHORIZED) {
		refinedError.errorMessage = "Sorry, You Are Not Authorized";
	} else if (error.status?.code === SC.FAIL.BAD_REQUEST) {
		refinedError.errorMessage = "Email or Password Is Required";
	} else if (
		error.status?.code === SC.FAIL.INTERNAL_SERVER_ERROR &&
		error?.message
	) {
		refinedError.errorMessage = error.message;
	}

	return refinedError;
}

export { processLoginResponseError };
