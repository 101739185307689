import apiSlice from "services/redux/reducers/apiSlice";
import { BACK_END_API_END_POINTS as AEP } from "app/settings/settings";

const customersApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		RCustomers: builder.mutation({
			query: (inputParams = {}) => ({
				url: AEP.customers.RCustomers.url,
				method: AEP.customers.RCustomers.method,
				body: { ...inputParams },
			}),
		}),
		UCustomers: builder.mutation({
			query: (inputParams = {}) => ({
				url: AEP.customers.UCustomers.url,
				method: AEP.customers.UCustomers.method,
				body: { ...inputParams },
			}),
		}),
	}),
});

export const { useRCustomersMutation, useUCustomersMutation } = customersApi;

export default customersApi;
