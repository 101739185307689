// Main App Routes

import { useRoutes, useLocation } from "custom_exports/all_hooks";
import { Navigate } from "react-router-dom";
import AppCover from "components/covers/AppCover";
import PageCover from "components/covers/PageCover";
import RequireAuthentication from "components/common/RequireAuthentication";
import LoadAppData from "components/common/LoadAppData";
import AppLayout from "components/layouts/app_layout/AppLayout";
import AppPopovers from "components/popovers/AppPopovers";
import AUTH_ROUTES from "components/routes/authRoutes";
import OVERVIEW_ROUTES from "components/routes/overViewRoutes";
import INVENTORY_ROUTES from "components/routes/inventoryRoutes";
import ORDERS_ROUTES from "components/routes/ordersRoutes";
import NAVIGATION_ITEMS from "app/navigations/navigationItems";
import { DEFAULT_APP_INDEX_PAGE_PATH } from "app/settings/appSettings";

function AppRoutes() {
	//--------------------------------- variables ---------------------------------//
	const currentLocation = useLocation();
	const currentLocationPath = currentLocation.pathname;

	const navigateToDefaultAppIndexPage = <Navigate to={DEFAULT_APP_INDEX_PAGE_PATH} />;

	const publicRoutes = [AUTH_ROUTES];

	const privateRoutes = [
		{
			element: <RequireAuthentication />,
			children: [
				{
					path: "/",
					element: <LoadAppData loadAll />,
					children: [
						{
							path: "/",
							element: <AppLayout />,
							children: [
								{
									path: "/",
									element: <AppPopovers />,
									children: [
										{
											index: true,
											element: navigateToDefaultAppIndexPage,
										},
										{
											path: "/",
											element: <PageCover />,
											children: [
												OVERVIEW_ROUTES,
												INVENTORY_ROUTES,
												ORDERS_ROUTES,
											],
										},
									],
								},
							],
						},
					],
				},
			],
		},
	];

	const routes = useRoutes([
		{
			path: "/",
			element: <AppCover />,
			children: [...publicRoutes, ...privateRoutes],
		},
		{ path: "*", element: navigateToDefaultAppIndexPage },
	]);

	//--------------------------------- contents ----------------------------------//
	const content =
		!NAVIGATION_ITEMS[currentLocationPath]?.disabled ||
		currentLocationPath === DEFAULT_APP_INDEX_PAGE_PATH
			? routes
			: navigateToDefaultAppIndexPage;

	return content;
}

export default AppRoutes;
