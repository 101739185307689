// Overview
import { MdDashboard } from "react-icons/md";
import { MdPendingActions } from "react-icons/md";
import { FaUserClock } from "react-icons/fa";

const OVERVIEW = {
	"/overview/dashboard": {
		id: "/overview/dashboard",
		title: "Dashboard",
		type: "single",
		icon: MdDashboard,
		url: "/overview/dashboard",
		disabled: true,
	},
	"/overview/newusers": {
		id: "/overview/newusers",
		title: "New Users",
		type: "single",
		icon: FaUserClock,
		url: "/overview/newusers",
		disabled: true,
	},
	"/overview/neworders": {
		id: "/overview/neworders",
		title: "New Orders",
		type: "single",
		icon: MdPendingActions,
		url: "/overview/neworders",
		disabled: true,
	},
};

export default OVERVIEW;
