// Category Drawer

import { useDispatch, useSelector, useTheme, useMediaQuery } from "custom_exports/all_hooks";
import { Drawer, Stack, Typography, IconButton, Divider } from "custom_exports/material_ui_items";
import CancelIcon from "@mui/icons-material/Cancel";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TreeView from "components/custom/tree/TreeView";
import {
	productCategoryActions,
	getShowProductsPageProductCategoryDrawer,
} from "services/redux/reducers/slice";
import K from "common/keywords";

function ProductCategoryDrawer(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	const theme = useTheme();
	const isViewingOnMobile = useMediaQuery(theme.breakpoints.down("tablet"));

	// ---- destructuring ---- //
	const { productCategories = [], handleOnClick: rootHandleOnClick } = props;
	const { setShowProductsPageProductCategoryDrawer } = productCategoryActions;

	// ---- assignments ---- //
	const dispatch = useDispatch();
	const showProductCategoryDrawer = useSelector(getShowProductsPageProductCategoryDrawer);

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //
	const handleOnClick = (id, data) => {
		if (id === K.hideProductCategoryDrawer) {
			dispatch(setShowProductsPageProductCategoryDrawer(false));
		}
	};

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Drawer
			variant={isViewingOnMobile ? "temporary" : "persistent"}
			keepMounted={true}
			ModalProps={{ keepMounted: true }}
			anchor={isViewingOnMobile ? "bottom" : "left"}
			open={showProductCategoryDrawer}
			sx={{
				width: 400,
				"& .MuiDrawer-paper": {
					position: "fixed",
					top: 125,
					left: 0,
					width: { mobile: "100%", tablet: 350, laptop: 400 },
					height: "calc(100% - 130px)",
					pl: { mobile: 2, sm: 3 },
					boxSizing: "border-box",
					overflow: "hidden",
					border: "none",
				},
			}}
			onClose={() => {
				handleOnClick(K.hideProductCategoryDrawer);
			}}
		>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Typography fontWeight={500}>Categories</Typography>
				<Stack direction="row">
					<IconButton onClick={() => rootHandleOnClick(K.resetProductCategoriesFilter)}>
						<RestartAltIcon />
					</IconButton>
					<IconButton onClick={() => handleOnClick(K.hideProductCategoryDrawer)}>
						<CancelIcon />
					</IconButton>
				</Stack>
			</Stack>
			<Divider sx={{ bgcolor: "primary.light", mb: 1 }} />
			<TreeView data={productCategories} {...props} />
		</Drawer>
	);

	return content;
}

export default ProductCategoryDrawer;
