import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
	name: "customer",
	initialState: { items: { dict: {}, list: [] } },
	reducers: {
		setCustomer: (state, action) => {
			const itemsAsList = action.payload;
			state.items.list = itemsAsList;
			for (let i = 0; i < itemsAsList.length; i++) {
				const item = itemsAsList[i];
				state.items.dict[item.id] = item;
			}
		},
		resetCustomer: (state, action) => {
			state.items = { dict: {}, list: [] };
		},
	},
});

const getCustomerItemsAsDict = (state) => state.customer.items.dict;

const getCustomerItemsAsList = (state) => state.customer.items.list;

export { getCustomerItemsAsDict, getCustomerItemsAsList };

export default customerSlice;
