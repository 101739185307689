// Auth Routing

import LoginPage from "components/pages/LoginPage";

const AUTH_ROUTES = {
	path: "auth",
	children: [{ path: "login", element: <LoginPage /> }],
};

export default AUTH_ROUTES;
