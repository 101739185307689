// Navigation Items

import OVERVIEW from "app/navigations/groups/overview";
import INVENTORY from "app/navigations/groups/inventory";
import ORDERS from "app/navigations/groups/orders";
import USERS from "app/navigations/groups/users";

const NAVIGATION_ITEMS = { ...OVERVIEW, ...INVENTORY, ...ORDERS, ...USERS };

export default NAVIGATION_ITEMS;
