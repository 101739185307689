/**
 * @file Helper Functions
 * @author Jeril Jose - 7777
 * @version 1.0
 */

/**
 * Run all callback functions in given array.
 * @param {array} callbacks Array of callbacks
 */
function runCallbacks(callbacks = []) {
	callbacks.forEach((callback) => {
		callback.args === undefined
			? callback.func()
			: callback.func(callback.args);
	});
}

function merge(params = {}) {
	let result = null;
	if (params.hasOwnProperty("dicts")) {
		let { dicts, onlyCommonKeys } = params;
		let [dictA, dictB] = [dicts[0], dicts[1]];

		if (onlyCommonKeys) {
			for (let key in dictB) {
				dictA.hasOwnProperty(key) && (dictA[key] = dictB[key]);
			}
			result = dictA;
		}
	}
	return result;
}

function convertDictItemsToList(dict) {
	let list = [];
	let item = null;

	for (let key in dict) {
		item = dict[key];
		list.push(item);
	}

	return list;
}

function convertFlatListToTreeList(params) {
	const {
		flatList = [],
		rootParentValue = null,
		parentKey = "parent",
		childrenKey = "children",
		idKey = "id",
	} = params;

	const doRecursion = (parent = rootParentValue) => {
		return flatList
			.filter((item) => item[parentKey] === parent)
			.map((item) => ({ ...item, [childrenKey]: doRecursion(item[idKey]) }));
	};

	const treeList = doRecursion();

	return treeList;
}

function convertFirstCharacterToUpperCase(string) {
	return string.replace(string[0], string[0].toUpperCase());
}

/**
 * Search function to find items by a query string, supporting nested keys.
 * @param {Array<Object>} data - Array of objects to search through.
 * @param {string} query - The search query string. The search is case-insensitive.
 * @param {string} key - The key or nested key (dot notation) to search within each object. For example, 'details.name'.
 * @returns {Array<Object>} - Array of matching objects where the specified key contains the query string.
 */
function search(data, query, key) {
	try {
		if (!query) {
			return data;
		}
		// Convert the query to lowercase for case-insensitive search
		const lowerCaseQuery = query.toLowerCase();

		// Function to get nested value from an object
		function getNestedValue(obj, path) {
			return path.split(".").reduce((value, key) => value && value[key], obj);
		}

		// Filter the data based on the query match
		return data.filter((item) => {
			const value = getNestedValue(item, key);
			return (
				typeof value === "string" &&
				value.toLowerCase().includes(lowerCaseQuery)
			);
		});
	} catch (error) {
		return data;
	}
}

export {
	runCallbacks,
	merge,
	convertDictItemsToList,
	convertFlatListToTreeList,
	convertFirstCharacterToUpperCase,
	search,
};
