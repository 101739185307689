// Default Page Cover

import {
	useState,
	useEffect,
	useDispatch,
	useSelector,
} from "custom_exports/all_hooks";

import { Outlet } from "react-router-dom";
import {
	Box,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Stack,
	Badge,
	Menu,
	MenuItem,
} from "custom_exports/material_ui_items";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import THEME from "app/settings/themeSettings";
import {
	cartActions,
	productCategoryActions,
	getTotalNumberOfCartItems,
	getUser,
	getShowProductsPageProductCategoryDrawer,
} from "services/redux/reducers/slice";
import K from "common/keywords";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { RequireUserRoleAsCustomer } from "components/common/RequirePermission";
import FavoriteIcon from "@mui/icons-material/Favorite";
import C from "common/constants";

function ProductsPageCover(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- destructuring ---- //
	const { pageDetails } = props;
	const { title: pageTitle, icon: PageTitleIcon } = pageDetails ?? {};
	const { setProductCategoriesFilter, setShowProductsPageProductCategoryDrawer } =
		productCategoryActions;
	const { toggleCartDrawer } = cartActions;

	// ---- assignments ---- //
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const [filterButtonAnchor, setFilterButtonAnchor] = useState(null);
	const [showFavouriteProducts, setShowFavouriteProducts] = useState(false);
	const headerBarProps = THEME.app.props.headerBar;
	const totalNumberOfCartItems = useSelector(getTotalNumberOfCartItems);
	const showProductCategoryDrawer = useSelector(
		getShowProductsPageProductCategoryDrawer
	);

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //
	const handleOnClick = (id, data) => {
		if (id === K.showCartDrawer) {
			if (totalNumberOfCartItems > 0) {
				dispatch(toggleCartDrawer({ show: true }));
			}
		} else if (id === K.showProductsPageFilterMenu) {
			let event = data;
			setFilterButtonAnchor(event.currentTarget);
		} else if (id === K.hideProductsPageFilterMenu) {
			setFilterButtonAnchor(null);
		} else if (id === K.showProductCategoryDrawer) {
			setFilterButtonAnchor(null);
			dispatch(setShowProductsPageProductCategoryDrawer(true));
		} else if (id === K.toggleFavouriteProducts) {
			setShowFavouriteProducts(!showFavouriteProducts);
		} else {
			console.log("unknown click id " + id);
		}
	};

	// ---- useEffects ---- //
	useEffect(() => {
		if (
			user?.role === C.user_roles.customer &&
			user?.profile?.favourite_products.length <= 0 &&
			showFavouriteProducts
		) {
			setShowFavouriteProducts(false);
		}
		if (showFavouriteProducts) {
			dispatch(setProductCategoriesFilter([]));
			dispatch(setShowProductsPageProductCategoryDrawer(false));
		}
	}, [
		user,
		showFavouriteProducts,
		setProductCategoriesFilter,
		setShowProductsPageProductCategoryDrawer,
		dispatch,
	]);

	useEffect(() => {
		if (showProductCategoryDrawer) {
			setShowFavouriteProducts(false);
		}
	}, [showProductCategoryDrawer, setShowFavouriteProducts, dispatch]);

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
			<AppBar
				position="sticky"
				color="inherit"
				elevation={0}
				sx={{ borderRadius: 3, zIndex: 900 }}
			>
				<Toolbar sx={{ justifyContent: "space-between" }}>
					<Stack direction="row" alignItems="center">
						<Typography
							sx={{
								fontWeight: 700,
								fontSize: 24,
								color: "grey.dark",
							}}
						>
							{pageTitle}
						</Typography>
						<Box sx={{ flexShrink: 0, ml: 0.75 }}>
							<IconButton
								sx={headerBarProps.icon.sx}
								onClick={(event) =>
									handleOnClick(
										K.showProductsPageFilterMenu,
										event
									)
								}
							>
								<FilterListIcon style={headerBarProps.icon.style} />
							</IconButton>
							<Menu
								anchorEl={filterButtonAnchor}
								open={Boolean(filterButtonAnchor)}
								onClose={() =>
									handleOnClick(K.hideProductsPageFilterMenu)
								}
							>
								<MenuItem
									onClick={() =>
										handleOnClick(K.showProductCategoryDrawer)
									}
								>
									Categories
								</MenuItem>
								<MenuItem disabled>Favourites</MenuItem>
							</Menu>
							<RequireUserRoleAsCustomer>
								{user?.profile?.favourite_products?.length > 0 && (
									<IconButton
										sx={{
											...headerBarProps.icon.sx,
											color: "orange.main",
										}}
										onClick={() =>
											handleOnClick(K.toggleFavouriteProducts)
										}
									>
										{showFavouriteProducts ? (
											<FavoriteIcon />
										) : (
											<FavoriteBorderIcon />
										)}
									</IconButton>
								)}
							</RequireUserRoleAsCustomer>
						</Box>
					</Stack>
					<Stack>
						<Box sx={{ flexShrink: 0, ml: 0.75 }}>
							<IconButton
								sx={headerBarProps.icon.sx}
								onClick={() => handleOnClick(K.showCartDrawer)}
							>
								<Badge
									badgeContent={totalNumberOfCartItems}
									color="primary"
								>
									<ShoppingCartOutlinedIcon
										style={headerBarProps.icon.style}
									/>
								</Badge>
							</IconButton>
						</Box>
					</Stack>
				</Toolbar>
			</AppBar>

			<Box sx={{ width: "100%", pt: 0.2 }}>
				<Outlet
					context={{ showFavouriteProducts, setShowFavouriteProducts }}
				/>
			</Box>
		</Box>
	);

	return content;
}

export default ProductsPageCover;
