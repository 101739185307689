import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
	name: "auth",
	initialState: { user: null, accessToken: null },
	reducers: {
		setCredentials: (state, action) => {
			const { user, accessToken } = action.payload;
			state.user = user;
			state.accessToken = accessToken;
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
		resetCredentials: (state, action) => {
			state.user = null;
			state.accessToken = null;
		},
	},
});

const getUser = (state) => state.auth.user;
const getAccessToken = (state) => state.auth.accessToken;

export { getUser, getAccessToken };

export default authSlice;
