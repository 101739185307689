// Main App Layout

import { useDispatch, useSelector } from "custom_exports/all_hooks";
import { Outlet } from "react-router-dom";
import { sideBarActions, getLeftSideBar } from "services/redux/reducers/slice";
import { Box, Toolbar, Container } from "custom_exports/material_ui_items";
import HeaderBar from "components/layouts/app_layout/header/HeaderBar";
import LeftSideBar from "components/layouts/app_layout/side_bars/left/LeftSideBar";

const { setLeftSideBar } = sideBarActions;

function AppLayout(props) {
	//--------------------------------- variables ---------------------------------//
	const dispatch = useDispatch();
	const leftSideBar = useSelector(getLeftSideBar);
	// const theme = useTheme();
	// console.log(useMediaQuery(theme.breakpoints.down("lg")));

	//--------------------------------- functions ---------------------------------//
	const handleLeftSideBarToggle = () => {
		let sideBar = { ...leftSideBar };
		sideBar.opened = !sideBar.opened;
		dispatch(setLeftSideBar(sideBar));
	};

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Box sx={{ display: "flex", width: "100%" }}>
			<HeaderBar
				leftSideBar={leftSideBar}
				handleLeftSideBarToggle={handleLeftSideBarToggle}
			/>
			<LeftSideBar
				leftSideBar={leftSideBar}
				handleLeftSideBarToggle={handleLeftSideBarToggle}
			/>
			<Box
				component="main"
				display="flex"
				flexDirection="column"
				sx={{
					width: "100%",
					height: "100vh",
					flexGrow: 1,
					// p: { xs: 2, sm: 3 },
				}}
			>
				<Toolbar style={{ minHeight: "56px" }} />
				<Container
					sx={{
						overflow: "auto",
						p: { xs: 0, sm: 0 },
						py: { xs: 0.3, sm: 0.3 },
						// pl: { xs: 2, sm: 2 },
						maxWidth: "100% !important",
					}}
				>
					<Outlet />
				</Container>
			</Box>
		</Box>
	);

	return content;
}

export default AppLayout;
