// Header Bar

import { useTheme } from "custom_exports/all_hooks";
import { AppBar, Toolbar, IconButton } from "custom_exports/material_ui_items";
import { MdOutlineMenuOpen, MdOutlineMenu } from "react-icons/md";
import HeaderContents from "components/layouts/app_layout/header/contents/HeaderContents";
import THEME from "app/settings/themeSettings";

function HeaderBar(props) {
	//--------------------------------- variables ---------------------------------//
	const { leftSideBar, handleLeftSideBarToggle } = props;
	const headerBarProps = THEME.app.props.headerBar;

	const theme = useTheme();

	const matchDownMD = true;

	const appBarParams = {
		position: "fixed",
		color: "inherit",
		elevation: 0,
		sx: { borderBottom: `3px solid`, borderColor: "primary.lighter" },
	};

	//--------------------------------- contents ----------------------------------//
	const header = (
		<Toolbar style={{ minHeight: 56 }}>
			<IconButton
				onClick={handleLeftSideBarToggle}
				edge={"start"}
				sx={headerBarProps.icon.sx}
			>
				{leftSideBar.opened ? (
					<MdOutlineMenuOpen style={headerBarProps.icon.style} />
				) : (
					<MdOutlineMenu style={headerBarProps.icon.style} />
				)}
			</IconButton>
			<HeaderContents />
		</Toolbar>
	);

	const appBar = <AppBar {...appBarParams}>{header}</AppBar>;

	const content = matchDownMD ? appBar : null;

	return content;
}

export default HeaderBar;
