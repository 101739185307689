// Orders Routing

import OrdersPage from "components/pages/orders/OrdersPage";

const ORDERS_ROUTES = {
	path: "orders",
	children: [{ path: "", element: <OrdersPage /> }],
};

export default ORDERS_ROUTES;
