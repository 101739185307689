// Users

import { MdEmojiPeople, MdPeopleAlt } from "react-icons/md";

const USERS = {
	"/users/staffs": {
		id: "/users/staffs",
		title: "Staffs",
		type: "single",
		icon: MdPeopleAlt,
		url: "/users/staffs",
		disabled: true,
	},
	"/users/customers": {
		id: "/users/customers",
		title: "Customers",
		type: "single",
		icon: MdEmojiPeople,
		url: "/users/customers",
		disabled: true,
	},
};

export default USERS;
