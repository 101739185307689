// Left Side Bar Header

import Navigations from "components/layouts/app_layout/side_bars/left/contents/navigation/Navigations";

function LeftSideBarContents(props) {
	//--------------------------------- variables ---------------------------------//

	//--------------------------------- contents ----------------------------------//
	const content = (
		<>
			<Navigations />
		</>
	);

	return content;
}

export default LeftSideBarContents;
