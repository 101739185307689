// Notification Badge Icon With Brief List DropDown

import { Box, IconButton, Badge } from "custom_exports/material_ui_items";
import { MdNotificationsNone } from "react-icons/md";
import THEME from "app/settings/themeSettings";

function Notification(props) {
	//--------------------------------- variables ---------------------------------//
	const headerBarProps = THEME.app.props.headerBar;
	//--------------------------------- contents ----------------------------------//
	const content = (
		<Box sx={{ flexShrink: 0, ml: 0.75 }}>
			<IconButton sx={headerBarProps.icon.sx}>
				<Badge badgeContent={4} color="primary">
					<MdNotificationsNone style={headerBarProps.icon.style} />
				</Badge>
			</IconButton>
		</Box>
	);

	return content;
}

export default Notification;
