import { createSlice } from "@reduxjs/toolkit";

function applyModification(product) {
	// console.log(typeof product.price);
	let modifiedProduct = {
		...product,
		// price: Number(product.price).toFixed(2),
	};

	return modifiedProduct;
}

function getModifiedProducts(products) {
	let modifiedProducts = [];
	for (let i = 0; i < products.length; i++) {
		const product = { ...products[i] };
		modifiedProducts.push(applyModification(product));
	}

	return modifiedProducts;
}

const productSlice = createSlice({
	name: "product",
	initialState: { items: { dict: {}, list: [] } },
	reducers: {
		setProduct: (state, action) => {
			const itemsAsList = getModifiedProducts(action.payload);
			state.items.list = itemsAsList;
			for (let i = 0; i < itemsAsList.length; i++) {
				const item = itemsAsList[i];
				state.items.dict[item.id] = item;
			}
		},
		resetProduct: (state, action) => {
			state.items = { dict: {}, list: [] };
		},
	},
});

const getProductItemsAsDict = (state) => state.product.items.dict;

const getProductItemsAsList = (state) => state.product.items.list;

export { getProductItemsAsDict, getProductItemsAsList };

export default productSlice;
