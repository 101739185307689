//-------------------------------- Theme Settings ---------------------------------//

import { COLORS as MUI_COLORS } from "custom_exports/material_ui_items";

const THEME = {
	app: {
		colors: {
			primary: { lighter: "rgb(242 255 234)", main: "#6bc637" },
			secondary: { main: "#ffffff" },
			background: { default: "#ffffff" },
			grey: {
				light: MUI_COLORS.grey["A400"],
				main: "grey",
				dark: MUI_COLORS.grey["A700"],
			},
			red: { light: MUI_COLORS.red["A400"], main: "red" },
			orange: { light: MUI_COLORS.orange["A400"], main: "orange" },
		},
		screen_breakpoints: {
			values: {
				mobile: 0,
				xs: 0,
				sm: 600,
				tablet: 640,
				md: 900,
				laptop: 1024,
				lg: 1200,
				desktop: 1200,
				xl: 1536,
			},
		},
		props: {
			headerBar: {
				icon: {
					sx: {
						bgcolor: "transparent",
						"&:hover": { bgcolor: "primary.lighter" },
						borderRadius: 1,
					},
					style: { color: "grey.main" },
				},
			},
		},
	},
};

export default THEME;
