import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReAuth from "services/api/api";

const apiSlice = createApi({
	baseQuery: baseQueryWithReAuth,
	endpoints: (builder) => ({}),
});

const apiMiddleware = apiSlice.middleware;
const apiReducer = apiSlice.reducer;
const apiReducerPath = apiSlice.reducerPath;

export { apiMiddleware, apiReducer, apiReducerPath };

export default apiSlice;
