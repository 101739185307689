// Left Side Bar Header

import { Stack, Chip } from "custom_exports/material_ui_items";
import Logo from "components/common/Logo";
import { VERSION } from "app/settings/appSettings";

function LeftSideBarHeader(props) {
	//--------------------------------- variables ---------------------------------//

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Stack direction="row" spacing={1} alignItems="center">
			<Logo
				logoName="rethinkBiocleanLogo1"
				alt="rethink-bioclean-logo"
				sx={{ height: 60 }}
			/>
			<Chip
				label={VERSION}
				size="small"
				sx={{
					height: 16,
					pt: 4.8,
					bgcolor: "transparent",
					color: "grey.main",
					"& .MuiChip-label": { fontSize: "0.625rem" },
				}}
			/>
		</Stack>
	);

	return content;
}

export default LeftSideBarHeader;
