// Navigations

import { Box } from "custom_exports/material_ui_items";
import NavigationGroups from "./NavigationGroups";
import NAVIGATIONS from "app/navigations/navigations";

function Navigations() {
	//--------------------------------- variables ---------------------------------//
	const navigations = NAVIGATIONS.leftSideBar.map((item) => {
		switch (item.type) {
			case "group":
				return <NavigationGroups key={item.id} group={item} />;

			default:
				return <></>;
		}
	});

	//--------------------------------- contents ----------------------------------//
	const content = <Box sx={{ pt: 4 }}>{navigations}</Box>;

	return content;
}

export default Navigations;
