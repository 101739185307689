// Order Accordion

import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Divider,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Stack,
	OutlinedInput,
	List,
	ListItem,
	ListItemText,
	Badge,
	Chip,
	Box,
} from "custom_exports/material_ui_items";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	RequireUserRoleAsCustomer,
	RequireUserRoleAsNotCustomer,
} from "components/common/RequirePermission";
import { convertFirstCharacterToUpperCase } from "common/helpers";
import K from "common/keywords";
import C from "common/constants";

function OrderAccordionItem(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- destructuring ---- //
	const { handleOnClick: rootHandleOnClick, order } = props;

	// ---- assignments ---- //
	let orderStatusesList = [];
	let orderStatusesReverseDict = {};
	Object.keys(C.orderStatuses).forEach((key) => {
		if (!["created"].includes(key)) {
			orderStatusesList.push({ title: key, id: C.orderStatuses[key] });
		}
		orderStatusesReverseDict[C.orderStatuses[key]] = key;
	});

	let statusBackgroundColor = "orange.light";
	if ([C.orderStatuses.completed].includes(order.status)) {
		statusBackgroundColor = "primary.light";
	} else if (
		[C.orderStatuses.cancelled, C.orderStatuses.rejected].includes(order.status)
	) {
		statusBackgroundColor = "red.light";
	}

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //
	const handleOnOrderStatusChange = (event, order) => {
		const {
			target: { value },
		} = event;

		rootHandleOnClick(K.UOrderStatus, {
			orderId: order.id,
			newStatusId: value,
		});
	};

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				sx={{
					"& .MuiAccordionSummary-content": {
						justifyContent: "space-between",
						alignItems: "center",
					},
				}}
			>
				<Stack direction={{ mobile: "column", sm: "row" }}>
					<RequireUserRoleAsNotCustomer>
						<Typography
							sx={{
								width: { mobile: 100, sm: 200 },
								flexShrink: 0,
								mr: 2,
								wordBreak: "break-word",
							}}
						>
							{order.placed_by_user_details.profile?.business_name}
						</Typography>
						<Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
					</RequireUserRoleAsNotCustomer>
					<Typography sx={{ color: "grey.main" }}>
						{new Date(Date.parse(order.created_on)).toLocaleString()}
					</Typography>
				</Stack>
				<RequireUserRoleAsNotCustomer>
					<FormControl
						size="small"
						sx={{ mr: { mobile: 1, sm: 5 }, width: 150 }}
						onClick={(event) => event.stopPropagation()}
					>
						{/* <InputLabel>update status</InputLabel> */}
						<Select
							value={order.status}
							// input={<OutlinedInput label="update status" />}
							sx={{ bgcolor: statusBackgroundColor }}
							onClick={(event) => event.stopPropagation()}
							onChange={(event) =>
								handleOnOrderStatusChange(event, order)
							}
						>
							{orderStatusesList.map((status) => (
								<MenuItem key={status.id} value={status.id}>
									{convertFirstCharacterToUpperCase(status.title)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</RequireUserRoleAsNotCustomer>
				<RequireUserRoleAsCustomer>
					<Chip
						label={convertFirstCharacterToUpperCase(
							orderStatusesReverseDict[order.status]
						)}
						sx={{
							mr: { mobile: 1, sm: 5 },
							width: 150,
							bgcolor: statusBackgroundColor,
						}}
					/>
				</RequireUserRoleAsCustomer>
			</AccordionSummary>
			<AccordionDetails>
				<List>
					{order.cart_items.map((item) => (
						<ListItem key={item.id}>
							<Badge
								badgeContent={item.quantity}
								color="primary"
								sx={{
									"& .MuiBadge-badge": { top: 14, right: -22 },
								}}
							>
								<ListItemText
									secondary={item.product_details.title}
									sx={{ width: { mobile: 230, sm: 250 } }}
								/>
							</Badge>
						</ListItem>
					))}
				</List>
				{order.suggestion && (
					<Box>
						<Typography
							variant="caption"
							sx={{ fontWeight: "500", color: "grey" }}
						>
							Order Suggestion
						</Typography>
						<Divider />
						<Typography variant="caption" sx={{ color: "black" }}>
							{order.suggestion}
						</Typography>
					</Box>
				)}
			</AccordionDetails>
		</Accordion>
	);

	return content;
}

export default OrderAccordionItem;
