/**
 * @file Custom React Hooks
 * @author Jeril Jose - 7777
 * @version 1.1
 * @function useEnumerate
 */

import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEnumerateActions } from "services/redux/reducers/slice";
import { runCallbacks } from "common/helpers";

/**
 * This function is created like a react hook, but actually not.
 * This will run all callbacks provided only up to given total
 * number of times.
 * @param {array} callbacks Array of callbacks
 * @param {number} totalCount Total Count
 * @param {object} params Extra params
 * @returns
 */
function useEnumerate(callbacks = [], totalCount = null, params = null) {
	const {
		callerId = null,
		forceCall = false,
		resetAllFirst = false,
		resetAllLast = false,
		persistAll = false,
		testCondition = true,
	} = params || {};

	const dispatch = useDispatch();
	const { setUseEnumerateHistory } = useEnumerateActions;
	const currentCountRef = useRef(0);
	const lastCalledTimeRef = useRef(0); //seconds

	let enumerateHistory = useSelector(
		(state) => state.useEnumerate.history[callerId || ""]
	);

	const dispatchEnumerateHistory = (currentCount, lastCalledTime) => {
		if (enumerateHistory === undefined && callerId) {
			dispatch(
				setUseEnumerateHistory({
					[callerId]: { currentCount, lastCalledTime },
				})
			);
		}
	};

	if (testCondition) {
		let currentTime = new Date().getTime();
		let callInterval = 1; //seconds
		totalCount = Number.isInteger(totalCount) ? totalCount : "infinte";

		dispatchEnumerateHistory(
			currentCountRef.current,
			lastCalledTimeRef.current
		);
		// console.log(enumerateHistory);

		let currentCount =
			enumerateHistory?.currentCount || currentCountRef.current;
		let lastCalledTime =
			enumerateHistory?.lastCalledTime || lastCalledTimeRef.current;

		if (resetAllFirst) {
			lastCalledTimeRef.current = 0;
			currentCountRef.current = 0;
			dispatchEnumerateHistory(0, 0);
		}

		if (!forceCall) {
			if ((currentTime - lastCalledTime) / 1000 > callInterval) {
				if (totalCount === "infinte" || currentCount < totalCount) {
					lastCalledTimeRef.current = currentTime;
					currentCountRef.current++;
					dispatchEnumerateHistory(
						currentCountRef.current,
						currentTime
					);
					runCallbacks(callbacks);
				}
			}
		} else {
			runCallbacks(callbacks);
		}

		if (resetAllLast) {
			lastCalledTimeRef.current = 0;
			currentCountRef.current = 0;
			dispatchEnumerateHistory(0, 0);
		}
	}

	return;
}

export { useEnumerate };
