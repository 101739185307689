//--------------------------------- App Settings ---------------------------------//

const APP_PROVIDER = "iOrders";

const VERSION = "1.2.1";

const APP_ACCOUNT = "Rethink Bioclean";

const DEFAULT_APP_INDEX_PAGE_PATH = "/inventory/products";

export { APP_PROVIDER, VERSION, APP_ACCOUNT, DEFAULT_APP_INDEX_PAGE_PATH };
