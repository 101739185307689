// import { useEnumerate, useDispatch, useRef } from "custom_exports/all_hooks";
// import { Routes, Route } from "react-router-dom";
// import { Cookies } from "react-cookie";
// import { authActions } from "services/redux/reducers/authSlice";
// import AppCover from "components/covers/AppCover";
// import RequireAuth from "components/common/RequireAuth";
// import LoginPage from "components/pages/LoginPage";
import AppRoutes from "components/routes/AppRoutes";

// const { setCredentials } = authActions;

function App() {
	//--------------------------------- variables ---------------------------------//
	// const currentCountRef = useRef(0);

	// const dispatch = useDispatch();

	//--------------------------------- functions ---------------------------------//
	// const setBackCredentialsToStore = () => {
	// 	const cookies = new Cookies();
	// 	let user = cookies.get("user");
	// 	user = user
	// 		? typeof user == "object"
	// 			? user
	// 			: JSON.parse(user)
	// 		: null;
	// 	let accessToken = localStorage.getItem("accessToken") || null;

	// 	user && dispatch(setCredentials({ accessToken, user }));
	// };

	// on initial load
	// useEnumerate([{ func: setBackCredentialsToStore }], currentCountRef, 1);

	//--------------------------------- contents ----------------------------------//
	const content = <AppRoutes />;

	return content;
}

export default App;
