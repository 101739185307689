/**
 * @file Api Error Manager
 * @author Jeril Jose - 7777
 * @version 1.0
 * @function processApiResponseError
 */

import { processLoginResponseError } from "app/error_handlers/api_error_manager";
import K from "common/keywords";

/**
 * Process response errors of each api used in this app.
 * @param {string} apiCallerId Id of api caller
 * @param {object} error Un-refined api response error
 * @returns Refined error after processing
 */
function processApiResponseError(apiCallerId, error) {
	let refinedError = {};

	if (apiCallerId === K.getUserWithTokens) {
		refinedError = processLoginResponseError(error);
	} else {
		refinedError = error;
	}

	return refinedError;
}

export { processApiResponseError };
