// Orders Accordion View

import { Box } from "custom_exports/material_ui_items";
import OrderAccordionItem from "components/pages/orders/accordion/OrderAccordionItem";

function OrdersAccordionView(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- destructuring ---- //
	const { orders } = props;

	//--------------------------------- contents ----------------------------------//
	const ordersAccordionView = orders.map((order) => {
		return <OrderAccordionItem key={order.id} order={order} {...props} />;
	});

	const content = <Box>{ordersAccordionView}</Box>;

	return content;
}

export default OrdersAccordionView;
