// Page Cover

import { useLocation } from "custom_exports/all_hooks";
import DefaultPageCover from "components/covers/pages/DefaultPageCover";
import NAVIGATION_ITEMS from "app/navigations/navigationItems";

function PageCover(props) {
	//--------------------------------- variables ---------------------------------//
	const currentLocation = useLocation();
	const pageDetails = NAVIGATION_ITEMS[currentLocation.pathname];

	const CustomPageCover = pageDetails.pageCover;

	//--------------------------------- contents ----------------------------------//
	let content = null;

	if (CustomPageCover) {
		content = <CustomPageCover pageDetails={pageDetails} />;
	} else if (pageDetails) {
		content = <DefaultPageCover pageDetails={pageDetails} />;
	} else {
		content = <span>Requested Page Not Found</span>;
	}

	return content;
}

export default PageCover;
