import { createSlice } from "@reduxjs/toolkit";
import { merge } from "common/helpers";

const sideBarSlice = createSlice({
	name: "sideBar",
	initialState: { left: { opened: false } },
	reducers: {
		setLeftSideBar: (state, action) => {
			state.left = merge({
				dicts: [state.left, action.payload || {}],
				onlyCommonKeys: true,
			});
		},
	},
});

const getLeftSideBar = (state) => state.sideBar.left;

export { getLeftSideBar };

export default sideBarSlice;
