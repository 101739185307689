import apiSlice from "services/redux/reducers/apiSlice";
import { BACK_END_API_END_POINTS as AEP } from "app/settings/settings";

const ordersApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		ROrders: builder.mutation({
			query: (inputParams = {}) => ({
				url: AEP.orders.ROrders.url,
				method: AEP.orders.ROrders.method,
				body: { ...inputParams, order_by: AEP.orders.ROrders.default_order_by },
			}),
		}),
		COrders: builder.mutation({
			query: (inputParams = {}) => ({
				url: AEP.orders.COrders.url,
				method: AEP.orders.COrders.method,
				body: { ...inputParams },
			}),
		}),
		UOrders: builder.mutation({
			query: (inputParams = {}) => ({
				url: AEP.orders.UOrders.url,
				method: AEP.orders.UOrders.method,
				body: { ...inputParams },
			}),
		}),
	}),
});

export const { useROrdersMutation, useCOrdersMutation, useUOrdersMutation } = ordersApi;

export default ordersApi;
