// React Ref Merge

function mergeRefs(...refs) {
	return (instance) => {
		refs.forEach((ref) => {
			if (typeof ref === "function") {
				ref(instance);
			} else if (ref != null) {
				ref.current = instance;
			}
		});
	};
}

export default mergeRefs;
