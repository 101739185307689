import apiSlice from "services/redux/reducers/apiSlice";
import { BACK_END_API_END_POINTS as AEP } from "app/settings/settings";

const productsApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		RProducts: builder.mutation({
			query: (inputParams = {}) => ({
				url: AEP.products.RProducts.url,
				method: AEP.products.RProducts.method,
				body: { ...inputParams },
			}),
		}),
		RProductCategories: builder.mutation({
			query: (inputParams = {}) => ({
				url: AEP.products.RProductCategories.url,
				method: AEP.products.RProductCategories.method,
				body: { ...inputParams },
			}),
		}),
	}),
});

export const { useRProductsMutation, useRProductCategoriesMutation } = productsApi;

export default productsApi;
