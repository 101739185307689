// Custom Scroll

import { Box } from "custom_exports/material_ui_items";

function Scroll(props) {
	//--------------------------------- variables ---------------------------------//
	const { children } = props;

	//--------------------------------- contents ----------------------------------//
	const content = <Box overflow="auto">{children}</Box>;

	return content;
}

export default Scroll;
