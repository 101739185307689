// Login Page

import {
	useState,
	useEffect,
	useRef,
	useNavigate,
	useDispatch,
	useSelector,
	useGetUserWithTokensMutation,
} from "custom_exports/all_hooks";
import { Box, Typography, Grid, Paper } from "custom_exports/material_ui_items";
import { Navigate } from "react-router-dom";
import { authActions, getUser, getAccessToken } from "services/redux/reducers/slice";
import { callApi } from "common/api_handlers/api_call_manager";
import A from "common/actions/actions";
import K from "common/keywords";
import LoginForm from "components/forms/LoginForm";
import ShowStatus from "components/common/ShowStatus";
import CopyRight from "components/common/CopyRight";
import Logo from "components/common/Logo";
import SRC from "custom_exports/all_file_items";

function confirmAccessTokenSavedInLocalStorage(params) {
	const { accessToken: activeAccessToken, setAccessTokenSavedInLocalStorage } = params;
	let accessTokenInLocalStorage = null;
	let accessTokensMatched = false;

	let timer = setInterval(() => {
		try {
			const localStorageUserAccessToken = JSON.parse(
				localStorage.getItem("persist:userAccessToken")
			);
			accessTokenInLocalStorage = JSON.parse(localStorageUserAccessToken.accessToken);

			if (accessTokenInLocalStorage === activeAccessToken) {
				accessTokensMatched = true;
				clearInterval(timer);
				setAccessTokenSavedInLocalStorage(true);
			}
		} catch (error) {
			console.log(error);
		}
	}, 0);

	setTimeout(() => {
		clearInterval(timer);
		!accessTokensMatched && setAccessTokenSavedInLocalStorage(false);
	}, 10000);
}

function LoginPage() {
	//--------------------------------- variables ---------------------------------//
	const { setCredentials } = authActions;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [loginErrorMessage, setLoginErrorMessage] = useState(null);
	const [accessTokenSavedInLocalStorage, setAccessTokenSavedInLocalStorage] = useState(null);
	const checkingAccessToken = useRef(null);

	const [getUserWithTokensApiCaller, getUserWithTokensApiResult] = useGetUserWithTokensMutation();

	// const user = useSelector(getUser);
	const accessToken = useSelector(getAccessToken);

	//--------------------------------- functions ---------------------------------//
	// ---- api managers ---- //
	const onApiResponse = ({ apiCallerId, ...response }) => {
		console.log(response);
		const { data } = response;
		if (apiCallerId === K.getUserWithTokens) {
			const [accessToken, user] = [data.users.item.access_token, data.users.item];
			checkingAccessToken.current = true;
			dispatch(setCredentials({ accessToken, user }));
			confirmAccessTokenSavedInLocalStorage({
				accessToken,
				setAccessTokenSavedInLocalStorage,
			});
		}
	};

	const onApiError = ({ apiCallerId, ...error }) => {
		if (apiCallerId === K.getUserWithTokens) {
			setLoginErrorMessage(error.errorMessage);
		}
	};

	const apiActions = { onApiResponse, onApiError };

	const callGetUserWithTokensApi = (apiInputParams = {}, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.getUserWithTokens,
			apiCaller: getUserWithTokensApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	// ---- handlers ---- //
	const handleOnSubmit = (inputData) => {
		callGetUserWithTokensApi(inputData);
	};

	const handleOnChange = (event) => {
		setLoginErrorMessage(null);
	};

	// ---- useEffects ---- //
	useEffect(() => {
		if (accessTokenSavedInLocalStorage !== null) {
			checkingAccessToken.current = false;
			setAccessTokenSavedInLocalStorage(null);
			navigate("/", { replace: true });
		}
	}, [accessTokenSavedInLocalStorage, navigate]);

	//--------------------------------- contents ----------------------------------//
	let content = <Navigate to="/" replace />;

	const loadingIndication = <ShowStatus statusType="loading" />;

	const loginPage = (
		<Grid container component="main" sx={{ height: "100vh" }}>
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{
					backgroundImage: `url(${SRC.rethinkBiocleanTruck1})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			/>
			<Grid item component={Paper} square elevation={6} xs={12} sm={8} md={5}>
				<Box
					sx={{
						my: 3,
						mx: 4,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Logo
						logoName="rethinkBiocleanLogo1"
						alt="rethink-bioclean-logo"
						style={{
							width: "150px",
							height: "90px",
							marginBottom: "10px",
						}}
					/>
					<Typography
						color="primary"
						variant="h5"
						gutterBottom
						sx={{ fontWeight: "600" }}
					>
						Hi, Welcome Back
					</Typography>
					<Typography
						color="grey.main"
						variant="caption"
						fontSize="16px"
						textAlign="center"
					>
						Enter your credentials to continue
					</Typography>
					<LoginForm
						handleOnChange={handleOnChange}
						handleOnSubmit={handleOnSubmit}
						loginErrorMessage={loginErrorMessage}
					/>
					<CopyRight sx={{ mt: 6, mb: 0 }} />
				</Box>
			</Grid>
		</Grid>
	);

	if (!accessToken) {
		content = getUserWithTokensApiResult.isLoading ? loadingIndication : loginPage;
	}

	if (checkingAccessToken.current) {
		content = loadingIndication;
	}

	return content;
}

export default LoginPage;
