import { createSlice } from "@reduxjs/toolkit";
import { merge } from "common/helpers";

const navigationSlice = createSlice({
	name: "navigation",
	initialState: { app: { active: "dashboard" } },
	reducers: {
		setAppNavigation: (state, action) => {
			state.app = merge({
				dicts: [state.app, action.payload || {}],
				onlyCommonKeys: true,
			});
		},
	},
});

const getAppNavigation = (state) => state.navigation.app;

export { getAppNavigation };

export default navigationSlice;
