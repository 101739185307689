// Cart Item

import {
	Stack,
	IconButton,
	ListItem,
	ListItemText,
	Typography,
	Divider,
} from "custom_exports/material_ui_items";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import K from "common/keywords";

function CartItem(props) {
	//--------------------------------- variables ---------------------------------//
	const { handleOnClick, cartItem } = props;
	//--------------------------------- contents ----------------------------------//
	const content = (
		<>
			<ListItem alignItems="flex-start" sx={{ justifyContent: "center" }}>
				<ListItemText
					primary={cartItem.title}
					secondary={
						<>
							<Typography component="span" variant="body2">
								Total Quantity : {cartItem.quantity}
							</Typography>
						</>
					}
				/>
				<Stack>
					<IconButton
						color="primary"
						onClick={() =>
							handleOnClick(K.incrementCartItem, {
								id: cartItem.id,
							})
						}
					>
						<AddCircleIcon />
					</IconButton>
					<IconButton
						sx={{ color: "red.main" }}
						onClick={() =>
							handleOnClick(K.decrementCartItem, {
								id: cartItem.id,
							})
						}
					>
						<RemoveCircleIcon />
					</IconButton>
				</Stack>
			</ListItem>
			<Divider />
		</>
	);

	return content;
}

export default CartItem;
