// Logout

import { useDispatch } from "custom_exports/all_hooks";
import { IconButton } from "custom_exports/material_ui_items";
import { MdPowerSettingsNew } from "react-icons/md";
import A from "common/actions/actions";

function Logout() {
	//--------------------------------- variables ---------------------------------//
	const dispatch = useDispatch();

	//--------------------------------- functions ---------------------------------//
	// ---- handlers ---- //
	const handleOnClick = (event) => {
		A.logout({ dispatch });
	};
	//--------------------------------- contents ----------------------------------//
	const content = (
		<IconButton onClick={handleOnClick}>
			<MdPowerSettingsNew />
		</IconButton>
	);

	return content;
}

export default Logout;
