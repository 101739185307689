// Orders Page

import {
	useEffect,
	useCallback,
	useSelector,
	useDispatch,
	useROrdersMutation,
	useUOrdersMutation,
	useRCustomersMutation,
} from "custom_exports/all_hooks";
import { Box } from "custom_exports/material_ui_items";
import {
	orderActions,
	customerActions,
	getUser,
	getOrderItemsAsList,
	getCustomerItemsAsList,
} from "services/redux/reducers/slice";
import ShowStatus from "components/common/ShowStatus";
import { callApi } from "common/api_handlers/api_call_manager";
import OrdersAccordionView from "components/pages/orders/accordion/OrdersAccordionView";
import Swal from "sweetalert2";
import { THEME } from "app/settings/settings";
import K from "common/keywords";
import C from "common/constants";

function OrdersPage(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //
	// ---- destructuring ---- //
	const { setOrder, updateOrder } = orderActions;

	const { setCustomer } = customerActions;

	// ---- assignments ---- //
	const dispatch = useDispatch();
	const [ROrdersApiCaller, ROrdersApiResult] = useROrdersMutation();
	const [UOrdersApiCaller, UOrdersApiResult] = useUOrdersMutation();
	const [RCustomersApiCaller, RCustomersApiResult] = useRCustomersMutation();
	const user = useSelector(getUser);
	const ordersAsList = useSelector(getOrderItemsAsList);
	const customersAsList = useSelector(getCustomerItemsAsList);

	//--------------------------------- functions ---------------------------------//
	// ---- api managers ---- //
	const onApiResponse = ({ apiCallerId, ...response }) => {
		console.log(response);
		const { data } = response;
		if (apiCallerId === K.ROrders) {
			dispatch(setOrder(data.orders.items));
		} else if (apiCallerId === K.UOrders) {
			dispatch(updateOrder(data.orders.items));
		} else if (apiCallerId === K.RCustomers) {
			dispatch(setCustomer(data.customers.items));
		} else {
			console.log("onApiResponse is not defined for" + apiCallerId);
		}
	};

	const onApiError = ({ apiCallerId, ...error }) => {
		console.log(error);
		if (apiCallerId === K.UOrders) {
			Swal.fire({
				text: "Order Updation Failed, Something Went Wrong..",
				icon: "error",
				confirmButtonColor: THEME.app.colors.red.main,
			});
		}
	};

	const apiActions = { onApiResponse, onApiError };

	const callROrdersApi = (apiInputParams, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.ROrders,
			apiCaller: ROrdersApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	const callUOrdersApi = (apiInputParams, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.UOrders,
			apiCaller: UOrdersApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	const callRCustomersApi = (apiInputParams, apiCallerId) => {
		callApi({
			apiCallerId: apiCallerId ?? K.RCustomers,
			apiCaller: RCustomersApiCaller,
			apiInputParams,
			apiActions,
		});
	};

	const getFilteredOrdersAsList = useCallback(() => {
		let filteredOrdersAsList = [...ordersAsList];

		filteredOrdersAsList.sort(
			(a, b) => Date.parse(b.created_on) - Date.parse(a.created_on)
		);

		return filteredOrdersAsList;
	}, [ordersAsList]);

	// ---- handlers ---- //
	const handleOnClick = (id, data) => {
		if (id === K.UOrderStatus) {
			callUOrdersApi({
				method: "update_orders_statuses",
				id: data.orderId,
				status: data.newStatusId,
			});
		} else {
			console.log("unknown click id " + id);
		}
	};

	// ---- useEffects ---- //
	useEffect(() => {
		if (ordersAsList.length <= 0) {
			callROrdersApi();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			user?.role &&
			user.role !== C.user_roles.customer &&
			customersAsList.length <= 0
		) {
			callRCustomersApi();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	//--------------------------------- contents ----------------------------------//
	const loadingIndication = (
		<ShowStatus
			statusType="loading"
			extraSX={{ bgcolor: "white", zIndex: 1000 }}
		/>
	);

	const content = (
		<>
			{ROrdersApiResult.isLoading && loadingIndication}
			<Box>
				<OrdersAccordionView
					orders={getFilteredOrdersAsList()}
					handleOnClick={handleOnClick}
				/>
			</Box>
		</>
	);

	return content;
}

export default OrdersPage;
