// Auth Related Actions
import { persistor } from "services/redux/store";
import {
	useEnumerateActions,
	authActions,
	productActions,
	productCategoryActions,
	cartActions,
	customerActions,
	orderActions,
} from "services/redux/reducers/slice";

const { resetUseEnumerate } = useEnumerateActions;
const { resetCredentials } = authActions;
const { resetProduct } = productActions;
const { resetProductCategory } = productCategoryActions;
const { resetCart } = cartActions;
const { resetCustomer } = customerActions;
const { resetOrder } = orderActions;

function logout(params) {
	let { dispatch, dispatchActions, clearStorePersist = false } = params || {};

	if (!dispatchActions) {
		dispatchActions = [
			{ action: resetCart },
			{ action: resetProduct },
			{ action: resetCustomer },
			{ action: resetOrder },
			{ action: resetProductCategory },
			{ action: resetUseEnumerate },
			{ action: resetCredentials },
		];
	}

	clearStorePersist && persistor.purge();

	if (dispatch) {
		for (let i = 0; i < dispatchActions.length; i++) {
			const dispatchAction = dispatchActions[i].action;
			const dispatchActionPayload = dispatchActions[i].payload;
			dispatchActionPayload
				? dispatch(dispatchAction(dispatchActionPayload))
				: dispatch(dispatchAction());
		}
	}
}

export { logout };
