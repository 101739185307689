// Orders

import OrdersPageCover from "components/covers/pages/OrdersPageCover";

import { MdNoteAlt } from "react-icons/md";
import { MdPending } from "react-icons/md";

const ORDERS = {
	"/orders": {
		id: "/orders",
		title: "Orders",
		type: "single",
		icon: MdNoteAlt,
		url: "/orders",
		pageCover: OrdersPageCover,
		disabled: false,
	},
	"/orders/pending": {
		id: "/orders/pending",
		title: "Pending Orders",
		type: "single",
		icon: MdPending,
		url: "/orders/pending",
		disabled: true,
	},
};

export default ORDERS;
