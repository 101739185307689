// Permissions Based Routing
import { useSelector } from "custom_exports/all_hooks";
import { getUser } from "services/redux/reducers/slice";
import C from "common/constants";

function RequirePermission(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- initializations ---- //

	// ---- destructuring ---- //
	const { children, userRoles } = props;

	// ---- assignments ---- //
	const loggedInUser = useSelector(getUser);
	const loggedInUserRole = loggedInUser?.role;

	const grantedUserRoles = userRoles?.granted;
	const deniedUserRoles = grantedUserRoles ? null : userRoles?.denied;

	const permissionGranted =
		grantedUserRoles?.includes(loggedInUserRole) ||
		(deniedUserRoles instanceof Array && !deniedUserRoles.includes(loggedInUserRole));

	//--------------------------------- functions ---------------------------------//
	//--------------------------------- contents ----------------------------------//

	const content = permissionGranted ? children : null;

	return content;
}

function RequireUserRoleAsCustomer(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- destructuring ---- //
	const { children } = props;

	//--------------------------------- contents ----------------------------------//
	const content = (
		<RequirePermission userRoles={{ granted: [C.user_roles.customer] }}>
			{children}
		</RequirePermission>
	);

	return content;
}

function RequireUserRoleAsNotCustomer(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- destructuring ---- //
	const { children } = props;

	//--------------------------------- contents ----------------------------------//
	const content = (
		<RequirePermission userRoles={{ denied: [C.user_roles.customer] }}>
			{children}
		</RequirePermission>
	);

	return content;
}

function RequireUserRoleAsStaff(props) {
	//--------------------------------- variables ---------------------------------//
	// ---- destructuring ---- //
	const { children } = props;

	//--------------------------------- contents ----------------------------------//
	const content = (
		<RequirePermission userRoles={{ granted: [C.user_roles.staff] }}>
			{children}
		</RequirePermission>
	);

	return content;
}

export { RequireUserRoleAsCustomer, RequireUserRoleAsNotCustomer, RequireUserRoleAsStaff };

export default RequirePermission;
