import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
	name: "order",
	initialState: { items: { dict: {}, list: [] }, requireRefresh: false },
	reducers: {
		setOrder: (state, action) => {
			const itemsAsList = action.payload;
			state.items.list = itemsAsList;
			for (let i = 0; i < itemsAsList.length; i++) {
				const item = { ...itemsAsList[i], listPosition: i };
				state.items.dict[item.id] = item;
			}
		},
		updateOrder: (state, action) => {
			const payload = action.payload;
			if (payload instanceof Array) {
				const items = payload;
				items.forEach((item) => {
					if (item.id in state.items.dict) {
						const itemListPosition = state.items.dict[item.id].listPosition;
						state.items.list[itemListPosition] = item;
						state.items.dict[item.id] = { ...item, listPosition: itemListPosition };
					}
				});
			} else {
				const item = payload;
				if (item.id in state.items.dict) {
					const itemListPosition = state.items.dict[item.id].listPosition;
					state.items.list[itemListPosition] = item;
					state.items.dict[item.id] = { ...item, listPosition: itemListPosition };
				}
			}
		},
		createOrder: (state, action) => {
			const payload = action.payload;
			if (state.items.list.length > 0) {
				if (payload instanceof Array) {
					const items = payload;
					items.forEach((item) => {
						if (!(item.id in state.items.dict)) {
							const itemListPosition = state.items.list.push(item) - 1;
							state.items.dict[item.id] = { ...item, listPosition: itemListPosition };
						}
					});
				} else {
					const item = payload;
					if (!(item.id in state.items.dict)) {
						const itemListPosition = state.items.list.push(item) - 1;
						state.items.dict[item.id] = { ...item, listPosition: itemListPosition };
					}
				}
			} //so that will auto refresh
		},
		resetOrder: (state, action) => {
			state.items = { dict: {}, list: [] };
			state.requireRefresh = false;
		},
	},
});

const getOrderItemsAsDict = (state) => state.order.items.dict;

const getOrderItemsAsList = (state) => state.order.items.list;

const getOrderRequireRefresh = (state) => state.order.requireRefresh;

export { getOrderItemsAsDict, getOrderItemsAsList, getOrderRequireRefresh };

export default orderSlice;
