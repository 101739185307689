// Grouping All Navigation Items

import NI from "app/navigations/navigationItems";

const LEFT_SIDEBAR_NAVIGATION_GROUPS = [
	{
		id: "overview",
		title: "OVERVIEW",
		type: "group",
		children: [
			NI["/overview/dashboard"],
			NI["/overview/newusers"],
			NI["/overview/neworders"],
		],
	},
	{
		id: "inventory",
		title: "INVENTORY",
		type: "group",
		children: [NI["/inventory/categories"], NI["/inventory/products"]],
	},
	{
		id: "orders",
		title: "ORDERS",
		type: "group",
		children: [NI["/orders"], NI["/orders/pending"]],
	},
	{
		id: "users",
		title: "USERS",
		type: "group",
		children: [NI["/users/staffs"], NI["/users/customers"]],
	},
];

const NAVIGATIONS = {
	leftSideBar: [...LEFT_SIDEBAR_NAVIGATION_GROUPS],
};

export default NAVIGATIONS;
