import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authActions } from "services/redux/reducers/slice";
import { BACK_END_API_URL, BACK_END_API_END_POINTS as AEP } from "app/settings/settings";
import A from "common/actions/actions";
import { STATUS_CODES as SC } from "common/constants";

const { setCredentials } = authActions;

const baseQuery = fetchBaseQuery({
	baseUrl: BACK_END_API_URL,
	credentials: "omit",
	prepareHeaders: (headers, { getState }) => {
		let accessToken = null;
		try {
			const localStorageUserAccessToken = JSON.parse(
				localStorage.getItem("persist:userAccessToken")
			);

			accessToken =
				"accessToken" in localStorageUserAccessToken
					? JSON.parse(localStorageUserAccessToken.accessToken)
					: getState().auth.accessToken;
		} catch (error) {
			accessToken = getState().auth.accessToken;
		}
		// getState().auth.accessToken || localStorage.getItem("accessToken");
		if (accessToken) {
			headers.set("Authorization", `Bearer ${accessToken}`);
		}
		return headers;
	},
});

// wrapping baseQuery inorder to get new access token using refresh
// token, if current access token is wrong or expired

const baseQueryWithReAuth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);

	if (result?.error?.status === SC.FAIL.UN_AUTHORIZED) {
		A.logout({ dispatch: api.dispatch });
		// const redirectToLogin = api.getState().auth.accessToken;
		// api.dispatch(logOut());
		// redirectToLogin && window.location.replace("/login");
	}
	if (result?.error?.status === "FETCH_ERROR") {
		A.logout(); // in future add a error page navigation
	}

	// use if refresh token is using
	// if (result?.error?.status === SC.fail.unAuthorized) {
	//     console.log("sending refresh token");
	//     // send refresh token to get new access token
	//     const refreshResult = await baseQuery(
	//         AEP.user.refreshAccessToken.url, api, extraOptions
	//     )

	//     if (refreshResult?.data) {
	//         const user = api.getState().auth.user;
	//         const accessToken = refreshResult.data.access;
	//         // store the new access token
	//         api.dispatch(setCredentials({user, accessToken}));
	//         // retry the original query with the new access token
	//         result = await baseQuery(args, api, extraOptions);
	//     }
	//     else {
	//         api.dispatch(logOut())
	//     }
	// }
	return result;
};

export default baseQueryWithReAuth;
