// App Popovers

import { Outlet } from "react-router-dom";
import CartDrawer from "components/drawers/cart/CartDrawer";

function AppPopovers(props) {
	//--------------------------------- variables ---------------------------------//

	//--------------------------------- functions ---------------------------------//

	//--------------------------------- contents ----------------------------------//
	const content = (
		<>
			<CartDrawer />
			<Outlet />
		</>
	);

	return content;
}

export default AppPopovers;
