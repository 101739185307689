// Logo

import { ButtonBase } from "custom_exports/material_ui_items";
import SRC from "custom_exports/all_file_items";

function Logo(props) {
	//--------------------------------- variables ---------------------------------//
	const { logoName, alt, style = {}, sx = {} } = props;
	const imageSrc = SRC[logoName];

	//--------------------------------- contents ----------------------------------//

	const content = (
		<ButtonBase disableRipple sx={sx}>
			<img src={imageSrc} alt={alt} style={style} />
		</ButtonBase>
	);

	return content;
}

export default Logo;
