// Header Bar Contents

import { useTheme, useMediaQuery } from "custom_exports/all_hooks";
import { Box, Stack } from "custom_exports/material_ui_items";
import SearchField from "components/fields/SearchField";
import Notification from "components/layouts/app_layout/header/contents/items/Notification";
import User from "components/layouts/app_layout/header/contents/items/User";
import Logout from "components/layouts/app_layout/header/contents/items/Logout";
import Logo from "components/common/Logo";

function HeaderContents() {
	//--------------------------------- variables ---------------------------------//
	const theme = useTheme();
	const isViewingOnMobile = useMediaQuery(theme.breakpoints.down("tablet"));

	//--------------------------------- contents ----------------------------------//
	const content = (
		<Stack
			direction="row"
			sx={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}
		>
			<Logo
				logoName="rethinkBiocleanLogo1"
				alt="rethink-bioclean-logo"
				sx={{ height: 0 }}
				style={{ height: 55 }}
			/>
			<Stack direction="row" sx={{ alignItems: "center" }}>
				{/* {!isViewingOnMobile && <SearchField />} */}
				{/* {isViewingOnMobile && <Box sx={{ width: "100%", ml: 1 }} />} */}

				{/* <Notification /> */}
				{!isViewingOnMobile && <User />}
				<Logout />
			</Stack>
		</Stack>
	);

	return content;
}

export default HeaderContents;
