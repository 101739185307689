// --------- Complete Constants --------- //
import APP_CONSTANTS from "app/appConstants";

// api
const STATUS_CODES = {
	SUCCESS: { OK: 200 },
	FAIL: {
		BAD_REQUEST: 400,
		UN_AUTHORIZED: 401,
		INTERNAL_SERVER_ERROR: 500,
	},
};

const CONSTANTS = { status_codes: STATUS_CODES, ...APP_CONSTANTS };

export { STATUS_CODES };

export default CONSTANTS;
