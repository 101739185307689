// --------- App Specific Constants --------- //

const user_roles = { super_admin: 1, admin: 2, staff: 3, customer: 4 };

const orderStatuses = {
	created: 1,
	placed: 2,
	accepted: 3,
	pending: 4,
	processed: 5,
	completed: 6,
	rejected: 7,
	cancelled: 8,
};

const APP_CONSTANTS = { user_roles, orderStatuses };

export default APP_CONSTANTS;
