// Custom Image

import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box } from "custom_exports/material_ui_items";
import { forwardRef } from "react";

import { convertRatioToCssCalc } from "./utils";

const Image = forwardRef((props, ref) => {
	//--------------------------------- variables ---------------------------------//
	const {
		alt,
		src,
		ratio,
		enableEffect,
		delayMethod,
		delayTime,
		effect = "opacity",
		placeholder,
		placeholderSrc,
		threshold,
		useIntersectionObserver,
		visibleByDefault,
		wrapperProps,
		wrapperClassName = "component-image-wrapper",
		scrollPosition,
		sx,
		imageSx,
		...extraProps
	} = props;

	//--------------------------------- functions ---------------------------------//

	//--------------------------------- contents ----------------------------------//
	const image = (
		<Box
			component={LazyLoadImage}
			alt={alt}
			src={src}
			delayMethod={delayMethod}
			delayTime={delayTime}
			effect={enableEffect ? effect : undefined}
			placeholder={placeholder}
			placeholderSrc={placeholderSrc}
			threshold={threshold}
			useIntersectionObserver={useIntersectionObserver}
			visibleByDefault={visibleByDefault}
			wrapperProps={wrapperProps}
			wrapperClassName={wrapperClassName}
			scrollPosition={scrollPosition}
			sx={{
				width: 1,
				height: 1,
				verticalAlign: "bottom",
				...(ratio && { position: "absolute", top: 0, left: 0 }),
				...(imageSx && imageSx),
			}}
		/>
	);

	const content = (
		<Box
			ref={ref}
			component="span"
			className="component-image"
			sx={{
				overflow: "hidden",
				position: "relative",
				verticalAlign: "bottom",
				display: "inline-block",
				...(ratio && { width: 1 }),
				"& span.component-image-wrapper": {
					width: 1,
					height: 1,
					verticalAlign: "bottom",
					backgroundSize: "cover !important",
					...(ratio && {
						pt: convertRatioToCssCalc(ratio),
					}),
				},
				...sx,
			}}
			{...extraProps}
		>
			{image}
		</Box>
	);

	return content;
});

export default Image;
