import { createSlice } from "@reduxjs/toolkit";

const useEnumerateSlice = createSlice({
	name: "useEnumerate",
	initialState: { history: {} },
	reducers: {
		setUseEnumerateHistory: (state, action) => {
			const newHistory = action.payload;
			const currentHistory = state.history;
			state.history = { ...currentHistory, ...newHistory };
		},
		resetUseEnumerate: (state, action) => {
			state.history = {};
		},
	},
});

// const getProductItemsAsDict = (state) => state.product.items.dict;

// const getProductItemsAsList = (state) => state.product.items.list;

// export {};

export default useEnumerateSlice;
