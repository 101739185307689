function convertRatioToCssCalc(ratio = null) {
	let cssCalc = "100%";
	if (ratio && ratio !== "1/1") {
		cssCalc = `calc(100% / ${ratio.replace("/", "*")})`;
	}

	return cssCalc;
}

export { convertRatioToCssCalc };
