// Inventory Routing

import ProductsPage from "components/pages/products/ProductsPage";

const INVENTORY_ROUTES = {
	path: "inventory",
	children: [
		{ path: "categories", element: <></> },
		{ path: "products", element: <ProductsPage /> },
	],
};

export default INVENTORY_ROUTES;
