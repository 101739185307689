// Products Card View

import { Box } from "custom_exports/material_ui_items";
import ProductCard from "components/pages/products/cards/ProductCard";

function ProductsCardView(props) {
	//--------------------------------- variables ---------------------------------//
	const { products } = props;

	//--------------------------------- contents ----------------------------------//
	const productsCardView = products.map((product) => {
		return <ProductCard key={product.id} product={product} {...props} />;
	});

	const content = (
		<Box
			sx={{
				display: "flex",
				flexWrap: "wrap",
				gap: 3,
				justifyContent: "space-evenly",
				p: 1,
			}}
		>
			{productsCardView}
		</Box>
	);

	return content;
}

export default ProductsCardView;
