// Inventory

import ProductsPageCover from "components/covers/pages/ProductsPageCover";
import { MdCategory } from "react-icons/md";
import { FaProductHunt } from "react-icons/fa";

const INVENTORY = {
	"/inventory/categories": {
		id: "/inventory/categories",
		title: "Categories",
		type: "single",
		icon: MdCategory,
		url: "/inventory/categories",
		disabled: true,
	},
	"/inventory/products": {
		id: "/inventory/products",
		title: "Products",
		type: "single",
		icon: FaProductHunt,
		url: "/inventory/products",
		pageCover: ProductsPageCover,
		disabled: false,
	},
};

export default INVENTORY;
