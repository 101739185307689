// Login Form

import {
	Box,
	TextField,
	Button,
	FormControlLabel,
	Checkbox,
} from "custom_exports/material_ui_items";
import { Formik } from "formik";
import YUP from "custom_exports/yup_items";

function LoginForm(props) {
	//--------------------------------- variables ---------------------------------//
	const { handleOnChange, handleOnSubmit, loginErrorMessage } = props;

	const loginFormInitialValues = { email: "", password: "" };

	const loginFormValidationSchema = YUP.object().shape({
		email: YUP.string()
			.email("Provide a valid email")
			.required("Email is required"),
		password: YUP.string().required("Password is required"),
	});

	//--------------------------------- contents ----------------------------------//
	const loginForm = (
		<Formik
			initialValues={loginFormInitialValues}
			validationSchema={loginFormValidationSchema}
			onSubmit={handleOnSubmit}
		>
			{({
				values,
				errors,
				touched,
				isSubmitting,
				handleBlur,
				handleChange,
				handleSubmit,
			}) => (
				<Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
					<TextField
						id="login-form-email"
						type="email"
						name="email"
						label={errors.email || "Email"}
						autoComplete="email"
						autoFocus
						required
						margin="normal"
						fullWidth
						value={values.email}
						error={Boolean(touched.email && errors.email)}
						onBlur={handleBlur}
						onChange={(event) => {
							handleChange(event);
							handleOnChange(event);
						}}
					/>
					<TextField
						id="login-form-password"
						type="password"
						name="password"
						label={errors.password || "Password"}
						autoComplete="current-password"
						required
						margin="normal"
						fullWidth
						value={values.password}
						error={Boolean(touched.password && errors.password)}
						onBlur={handleBlur}
						onChange={(event) => {
							handleChange(event);
							handleOnChange(event);
						}}
					/>
					{loginErrorMessage && (
						<p style={{ color: "red", textAlign: "center" }}>
							{loginErrorMessage}
						</p>
					)}
					<Button
						type="submit"
						variant="contained"
						fullWidth
						sx={{ mt: 3, mb: 2 }}
						disabled={isSubmitting}
					>
						SIGN IN
					</Button>
				</Box>
			)}
		</Formik>
	);

	const content = loginForm;

	return content;
}

export default LoginForm;
